
	frappe.templates['booking_credit_dashboard'] = `<div class="booking-credit-dashboard">
	<div class="col-xs-12">
		{% if balance.length %}
		<h6 style="margin-bottom: 25px;">{{ __("Balance for {0} on {1}", [customer, frappe.datetime.global_date_format()]) }}</h6>
		<span class="inline-graph">
			{% for bal in balance %}
			<span class="inline-graph-half">
				{% if bal.balance <= 0 %}
				<span class="inline-graph-count">
					{% if bal.booking_credit_type %}
					{{ __("{0}: {1} {2}", [bal.booking_credit_type, bal.balance, __("Credits")]) }}
					{% else %}
					{{ __("{0} {1}", [bal.balance, __("Credits")]) }}
					{% endif %}
				</span>
				<span class="inline-graph-bar">
					<span class="inline-graph-bar-inner dark"
						style="width: {{ cint(bal.balance/total * 100) }}%">
					</span>
				</span>
				{% endif %}
			</span>
			<span class="inline-graph-half" title="{{ __("Credits") }}">
				{% if bal.balance > 0 %}
				<span class="inline-graph-count">
					{% if bal.booking_credit_type %}
					{{ __("{0}: {1} {2}", [bal.booking_credit_type, bal.balance, __("Credits")]) }}
					{% else %}
					{{ __("{0} {1}", [bal.balance, __("Credits")]) }}
					{% endif %}
				</span>
				<span class="inline-graph-bar">
					<span class="inline-graph-bar-inner dark"
						style="width: {{ cint(bal.balance/total * 100) }}%">
					</span>
				</span>
				{% endif %}
			</span>
			{% endfor %}
		</span>
		{% else %}
		<h6 style="margin-bottom: 25px;">{{ __("Zero balance for {0} on {1}", [customer, frappe.datetime.global_date_format()]) }}</h6>
		{% endif %}
	</div>
</div>
`;
