import { Calendar } from "@fullcalendar/core";

import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
import dayGridPlugin from "@fullcalendar/daygrid";

export class PMViewBaseCalendar {
	eventSources = [];

	/**
	 * @param {Object} opts
	 * @param {import("./ProjectManagementView.js").ProjectManagementView} opts.view
	 * @param {HTMLElement} opts.parent
	 */
	constructor({ view, parent }) {
		this.view = view;
		this.parent = $(parent).get(0);
	}

	async make() {
		this.calendar_element = document.createElement("div");
		this.calendar_element.classList.add("fc--unstyled", "fc--project-management-view");
		this.parent.appendChild(this.calendar_element);

		const calendar_options = await this.get_calendar_options();
		this.fullcalendar = new Calendar(this.calendar_element, calendar_options);
		this.fullcalendar.render();

		$(this.parent).find(".fc-today-button").prepend(frappe.utils.icon("today"));
		this.forwardToEventSources("mount", this);
	}

	destroy() {
		this.fullcalendar?.destroy();
		this.fullcalendar = null;
		this.calendar_element?.remove();
		this.calendar_element = null;
		this.forwardToEventSources("unmount", this);
	}

	getFilters() {
		return this.view.getFilters();
	}

	get_calendar_options() {
		return {
			// Base config
			locale: frappe.get_cookie("preferred_language") || frappe.boot.lang || "en",
			// timeZone: frappe.boot.time_zone.user || frappe.boot.time_zone.system || "UTC",
			// timeZone: "UTC",
			firstDay: frappe.datetime.get_first_day_of_the_week_index(),
			// schedulerLicenseKey: frappe.boot.fullcalendar_scheduler_licence_key,
			plugins: [
				timeGridPlugin,
				listPlugin,
				interactionPlugin,
				dayGridPlugin,
			],

			// Translations
			allDayContent: __("All Day"),
			buttonText: {
				today: __("Today"),
				month: __("Month"),
				week: __("Week"),
				day: __("Day"),
			},

			// Appearance
			height: "68vh",

			// Views
			initialView: "dayGridWeek",
			headerToolbar: {
				left: "prev,next today",
				center: "title",
				right: "dayGridMonth,dayGridWeek,dayGridDay,listWeek",
			},

			// Features
			editable: true,
			selectable: true,
			displayEventTime: false,
			selectMirror: false,
			showNonCurrentDates: true,
			weekends: true,

			events: this.fetchEvents.bind(this),
			eventOrder: ["start", "doctype", "-duration", "-weight", "allDay", "title"],

			// Rendering
			eventContent: this.eventContent?.bind(this),
			eventDidMount: this.onEventDidMount?.bind(this),
			eventWillUnmount: this.onEventWillUnmount?.bind(this),

			// Interactions
			eventDrop: this.onEventDrop?.bind(this),
			eventResize: this.onEventResize?.bind(this),
			select: this.onSelect?.bind(this),
			eventClick: this.onEventClick?.bind(this),
		};
	}

	async fetchEvents(parameters, callback) {
		const promises = this.eventSources.map((s) => (s).fetch(parameters))
		const all = await Promise.all(promises);
		const events = await this.postProcessEvents(all.flat(), all);
		if (!events) throw new Error("Please return events from postProcessEvents");
		return callback(events);
	}

	async postProcessEvents(events) {
		return events;
	}

	forwardToEventSources(method, ...args) {
		try {
			this.eventSources.forEach((s) => s[method]?.(...args));
		} catch(e) {
			console.error(e);
		}
	}

	onEventClick(...args) {
		this.forwardToEventSources("onEventClick", ...args);
	}

	onEventDrop(...args) {
		this.forwardToEventSources("onEventDrop", ...args);
	}

	onEventResize(...args) {
		this.forwardToEventSources("onEventResize", ...args);
	}

	onSelect(...args) {
		this.forwardToEventSources("onSelect", ...args);
	}

	onEventDidMount(...args) {
		this.forwardToEventSources("onEventDidMount", ...args);
	}

	onEventWillUnmount(...args) {
		this.forwardToEventSources("onEventWillUnmount", ...args);
	}

	setupLegend(...args) {
		this.forwardToEventSources("setupLegend", ...args);
	}

	eventContent(info) {
		const html = info.event.extendedProps.html_title;
		if (typeof html === "string") {
			return { html };
		} else if (html instanceof HTMLElement) {
			return { domNodes: [html.cloneNode(true)] };
		} else {
			return String(info.event.title ?? "");
		}
	}

	refresh() {
		this.fullcalendar.refetchResources();
		this.fullcalendar.refetchEvents();
	}
}
