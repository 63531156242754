/**
 * @typedef {import("./PMViewBaseCalendar").PMViewBaseCalendar} Calendar
 */

let fetchDocumentTitle_memo = {};
export async function getDocumentTitle(doctype, docname) {
	const key = `${doctype}:${docname}`;
	if (fetchDocumentTitle_memo[key] === undefined) {
		fetchDocumentTitle_memo[key] = fetchDocumentTitle(doctype, docname);
	}
	const res = await fetchDocumentTitle_memo[key];
	fetchDocumentTitle_memo[key] = res;
	return res;
}
async function fetchDocumentTitle(doctype, docname) {
	await frappe.model.with_doctype(doctype);
	const title_field = frappe.get_meta(doctype).title_field || "name";
	if (title_field && title_field !== "name") {
		try {
			const res = await frappe.db.get_value(doctype, docname, title_field);
			const title = res?.message?.[title_field] || docname;
			return title;
		} catch (e) {
			console.error(e); // eslint-disable-line no-console
		}
	}
	return docname;
}

// https://github.com/fullcalendar/fullcalendar/blob/main/packages/core/src/api/EventApi.ts
const FULLCALENDAR_EVENT_KEYS = [
	"source",
	"start",
	"end",
	"id",
	"groupId",
	"allDay",
	"title",
	"url",
	"display",
	"startEditable",
	"durationEditable",
	"constraint",
	"overlap",
	"allow",
	"backgroundColor",
	"borderColor",
	"textColor",
	"classNames",
	"extendedProps",
	"resourceId",
]

/**
 * Calculate a 32 bit FNV-1a hash
 * Found here: https://gist.github.com/vaiorabbit/5657561
 * Ref.: http://isthe.com/chongo/tech/comp/fnv/
 *
 * @param {string} str the input value
 * @param {number} [hval] optionally pass a seed
 * @returns {number}
 */
function stringHash32(str, hval = 0x811c9dc5) {
	for (let i = 0, l = str.length; i < l; ++i){
		hval ^= str.charCodeAt(i);
		hval += (hval << 1) + (hval << 4) + (hval << 7) + (hval << 8) + (hval << 24);
	}
	return hval >>> 0;
}

// Utility functions
function format_ymd(date) {
	return moment(date).format("YYYY-MM-DD");
}


export class PMViewEventSource {
	/** @type {Calendar?} */ calendar = null;

	get doctype() {
		throw new Error("Not implemented");
		// return "Task Assignment";
	}

	get method() {
		return "frappe.desk.calendar.get_events";
	}

	get mainKeys() {
		throw new Error("Not implemented");
		// return ["project", "task", "employee"];
	}

	get field_map() {
		throw new Error("Not implemented");
		// return {
		// 	name: "name",
		// 	start: "start_date",
		// 	end: "end_date",
		// 	weight: "duration",
		// 	project: "project",
		// 	task: "task",
		// }
	}

	mount(/** @type {Calendar} */ calendar) {
		this.calendar = calendar;
	}

	async fetch({ start, end }) {
		const args = await this.getArgs({ start, end });
		const events = await frappe.xcall(this.method, args);
		return await this.prepareEvents(events);
	}

	/** @protected */ async getArgs({ start, end }) {
		return {
			doctype: this.doctype,
			start: format_ymd(start),
			end: format_ymd(end),
			filters: await this.getFilters(),
			field_map: this.field_map,
			fields: Object.values(this.field_map),
		};
	}

	/** @protected */ getFilters() {
		if (this.calendar?.view?.doctype !== this.doctype) {
			return [];
		}
		return this.calendar?.getFilters?.() || [];
	}

	/** @protected */ async prepareEvents(/** @type {Array} */ events) {
		/** @type {Array} */ let evts = (events || []).map(this.prepareEvent.bind(this));
		evts = await Promise.all(evts);
		evts = evts.flat();
		evts = this.postProcessEvents(evts);
		return evts;
	}

	/** @protected @returns {Array} */ postProcessEvents(/** @type {Array} */ events) {
		return events;
	}

	async prepareEvent(evt) {
		const info = Object.assign({}, evt);
		info.extendedProps = info.extendedProps || {};
		info.extendedProps.sourceData = evt;
		this.parseEvent(info);

		this.importEventDocinfo(info);
		this.importEventTimeinfo(info);
		this.importEventAppearance(info);
		this.importEventWeight(info);

		const { title, description } = await this.importEventText(info);
		info.extendedProps.html_title = title;
		info.extendedProps.html_description = description;
		info.title = title.textContent;

		return info;
	}

	/** @protected */ parseEvent(info) {
		info.extendedProps = info.extendedProps || {};
		info.classNames = info.classNames || [];
		for (const [target, source] of Object.entries(this.field_map)) {
			info[target] = info[source];
			if (source !== target) {
				delete info[source];
			}
		}
		for (const k of Object.keys(info)) {
			if (!FULLCALENDAR_EVENT_KEYS.includes(k)) {
				info.extendedProps[k] = info[k];
				delete info[k];
			}
		}
		return info;
	}

	/** @private */ isDocWritableFromEvent(event) {
		const { doctype, name, docstatus } = this.grabEventDocinfo(event);
		if (!doctype || !name) {
			return false;
		}
		if (doctype !== this.doctype) {
			return false;
		}
		if (docstatus != 0) {
			return false;
		}
		if (!frappe.model.can_write(doctype)) {
			return false;
		}
		return true;
	}

	async onSelect(info) {
		// this.doGuiCreateDocument(info);
	}

	async onEventDrop(info) {
		if (this.isDocWritableFromEvent(info)) {
			this.doUpdateDocument(info);
		}
	}

	async onEventResize(info) {
		if (this.isDocWritableFromEvent(info)) {
			this.doUpdateDocument(info);
		}
	}

	async onEventClick(info) {
		if (this.isDocWritableFromEvent(info)) {
			// Prevent the browser from opening the link's destination (Form).
			info.jsEvent?.preventDefault();
			info.jsEvent?.stopPropagation();

			this.doGuiEditDocument(info);
		}
	}

	onEventDidMount(info) {
		this.mountEventWeight(info);
		this.mountEventPopover(info);
	}

	onEventWillUnmount(info) {
		this.unmountEventPopover(info);
	}

	/** @protected */ async doGuiCreateDocument(info) {
		const doc = frappe.model.get_new_doc(this.doctype);
		this.exportEvent(doc, info);
		this.openQuickEntry(doc);
	}

	/** @protected */ async doGuiEditDocument(info) {
		const { doctype, name } = this.grabEventDocinfo(info);
		const doc = await frappe.db.get_doc(doctype, name);
		this.openQuickEntry(doc);
	}

	/** @protected */ async doUpdateDocument(info) {
		const values = {};
		this.exportEvent(values, info);

		const { doctype, name } = this.grabEventDocinfo(info);
		await frappe.db.set_value(doctype, name, values);

		this.refreshCalendar();
	}

	/** @protected */ openQuickEntry(doc) {
		const callback = () => this.refreshCalendar();
		frappe.ui.form.make_quick_entry(doc.doctype, callback, null, doc);
	}

	/** @protected */ mountEventWeight({ el, event }) {
		let h = Number(event.extendedProps.weight) || 1;
		h = Math.max(0.25, Math.min(h, 24));
		h = `calc(${h} * var(--pm-event-base-height, 16px) + ${Math.max(0, h - 1)} * var(--pm-event-gap, 0px))`;
		el.style.setProperty("--pm-event-height", h);
	}

	/** @protected */ mountEventPopover({ el, event }) {
		const opts = {
			title: frappe.utils.html2text(event.title),
			placement: "auto",
			// trigger: "manual",
			trigger: "hover",
			delay: { show: 250, hide: 0 },
			// container: this.calendar?.list_view.page.parent || document.body,
		};

		const html = event.extendedProps.html_description;
		if (html) {
			opts.html = true;
			opts.content = html;
			opts.title = __(event.extendedProps.parenttype ?? event.extendedProps.doctype);
		}
		const $el = $(el);
		$el.popover(opts);

		// const toggler = (show = false) => () => {
		// 	const tip = $el.data("bs.popover")?.tip;
		// 	const hovered = tip?.matches(":hover") || el.matches(":hover");

		// 	if (show && hovered) {
		// 		$el.popover("show");
		// 		tip?.removeEventListener("mouseleave", delayedHide);
		// 		tip?.addEventListener("mouseleave", delayedHide);
		// 	} else if (!show && !hovered) {
		// 		$el.popover("hide");
		// 	}
		// }
		// const hide = toggler(false);
		// const show = toggler(true);
		// const delayedHide = () => hide;

		// el.addEventListener("mouseenter", () => setTimeout(show, 500));
		// el.addEventListener("mouseleave", delayedHide);
	}

	/** @protected */ unmountEventPopover({ el }) {
		$(el).popover("dispose");
	}

	/** @protected */ refreshCalendar() {
		this.calendar?.refresh();
	}

	/** @protected */ exportEvent(targetDocument, info) {
		this.exportEventTime(targetDocument, info);
		return targetDocument;
	}

	/** @protected */ exportEventTime(targetDocument, info) {
		if (!info) return targetDocument;
		const { start, end } = info.event || info;
		// End date is exclusive in fullcalendar, but inclusive in DB.
		targetDocument[this.field_map.start] = format_ymd(start);
		targetDocument[this.field_map.end] = format_ymd(frappe.datetime.add_days(end, -1));
		return targetDocument;
	}

	/** @protected */ exportEventDocinfo(targetDocument, info) {
		if (!info) return targetDocument;
		const { doctype, name } = this.grabEventDocinfo(info);
		targetDocument.doctype = doctype;
		targetDocument.name = name;
		return targetDocument;
	}

	/** @protected */ grabEventDocinfo(infoOrEvent) {
		if (!infoOrEvent) return { doctype: this.doctype, name: null };
		const { doctype, name, docstatus } = (infoOrEvent.event || infoOrEvent).extendedProps;
		return { doctype, name, docstatus };
	}

	/** @protected */ async buildEventTextPart(fieldname, info) {
		const map_field_to_doctype = (x) => frappe.model.unscrub(x);
		const name = info?.[fieldname] ?? info?.extendedProps?.[fieldname] ?? null;
		if (!name) return null;
		const doctype = map_field_to_doctype(fieldname);
		const title = await getDocumentTitle(doctype, name) || name
		return { doctype, name, fieldname, title };
	}

	/** @protected */ async importEventText(info) {
		let parts = this.mainKeys.map((key) => this.buildEventTextPart(key, info));
		parts = await Promise.all(parts);

		const title = document.createElement("div");
		title.classList.add("ellipsis", "text-wrap");
		const description = document.createElement("dl");

		for (const [index, part] of parts.entries()) {
			if (!part) continue;
			{
				// Build title: bolden first part, then concatenate everything with line breaks in between
				const div = document.createElement("div");
				title.appendChild(div);

				div.textContent = part.title;
				if (index === 0) {
					div.classList.add("strong");
				} else if (index >= 2) {
					div.classList.add("text-small");
					div.style.opacity = 0.9;
				}
			}
			{
				// Build description: linkify all parts
				const div = document.createElement("div");
				const dt = document.createElement("dt");
				const dd = document.createElement("dd");
				description.appendChild(div);
				div.appendChild(dt);
				div.appendChild(dd);

				if (part.doctype && part.name) {
					const a = document.createElement("a");
					dd.appendChild(a);

					dt.textContent = __(part.doctype);
					a.href = frappe.utils.get_form_link(part.doctype, part.name);
					// cannot have link preview inside a popover
					a.textContent = part.title;
				} else {
					dt.textContent = __(frappe.model.unscrub(part.fieldname));
					dd.textContent = part.title;
				}
			}
		}

		return { title, description };
	}

	/** @protected */ importEventWeight(info) {
		const { extendedProps: props } = info;

		if (props.weight) return; // no processing needed

		if (props.duration) {
			props.weight = Math.round(props.duration / 60) / 60; // in hours, rounded to 1/60 hour
		} else if (props.hours) {
			props.weight = props.hours;
		}
		if (isNaN(props.weight)) {
			delete props.weight;
		}
	}

	/** @protected */ importEventTimeinfo(info) {
		// End date is inclusive in DB, but exclusive in fullcalendar.
		info.end = frappe.datetime.add_days(info.end, 1);
		info.allDay = true;

		// show event on single day if start or end date is invalid
		// if (!frappe.datetime.validate(info.start) && info.end) {
		// 	info.start = frappe.datetime.add_days(info.end, -1);
		// }
		// if (info.start && !frappe.datetime.validate(info.end)) {
		// 	info.end = frappe.datetime.add_days(info.start, 1);
		// }
		// info.start = moment(info.start, 0).toDate();
		// info.end = moment(info.end, 0).toDate();
	}

	/** @protected */ importEventDocinfo(info) {
		const { extendedProps: props } = info;

		props.doctype ??= this.doctype;
		props.name ??= null;
		props.docstatus ??= 0;

		if (props.name) {
			info.id = props.name;
			info.groupId = props.doctype + "/" + props.name;
			if (props.parenttype && props.parent) {
				info.editable = frappe.model.can_write(props.parenttype);
				info.url = frappe.utils.get_form_link(props.parenttype, props.parent);
			} else {
				info.editable = frappe.model.can_write(props.doctype);
				info.url = frappe.utils.get_form_link(props.doctype, props.name);
			}
		}

		// Do not allow submitted/cancelled events to be updated
		if (props.docstatus && props.docstatus > 0) {
			info.editable = false;
		}
	}

	/** @returns {"solid" | "border" | "dashed" | "striped"} */
	grabEventStyle(info) {
		return "solid";
	}

	grabEventColor(info) {
		let color = info.color ?? info.extendedProps?.color;

		if (!frappe.ui.color.validate_hex(color) || !color) {
			// color name as text
			if (color) {
				color = frappe.ui.color.get_color_shade(color, "default");
			} else {
				// color = frappe.ui.color.get("blue", "default");
				color = null;
			}
		}

		if (color) {
			return color;
		}

		const getValue = (k) => String(info.extendedProps?.[k] ?? "");
		const key1 = getValue(this.mainKeys[0]);
		const hash1 = (stringHash32(key1) >>> 16) % 360;

		const hue = Math.round((hash1) % 360);
		const saturation = 50;
		const adjust = 20 + .5 * hue - (6.2e-2 * hue)**2 + (1.895e-2 * hue)**3;
		const lightness = 60 - adjust / 2;
		color = `hsl(${hue}, ${saturation}%, ${lightness}%)`;
		color = frappe.ui.color.parse_color(color);
		return color;
	}

	/** @protected */ importEventAppearance(info) {
		info.classNames = info.classNames || [];

		const color = this.grabEventColor(info);
		// const contrastColor = frappe.ui.color.get_contrast_color(color);
		const emptyColor = "var(--fc-page-bg-color)";

		const style = this.grabEventStyle(info);
		info.classNames.push("ellipsis", `pm-style--${style}`);

		if (style === "dashed" || style === "border") {
			info.backgroundColor = emptyColor;
			info.borderColor = color;
			info.textColor = color;
		} else if (style === "striped") {
			info.backgroundColor = color;
			info.borderColor = color;
			info.textColor = "#000";
		} else {
			info.backgroundColor = color;
			info.borderColor = color;
			info.textColor = "#fff";
		}
		info.color = info.textColor;
	}

	setupLegend(/** @type {HTMLElement} */ _container) {
		// pass
	}

	makeLegendItem(label, props) {
		const item = document.createElement("div");
		item.classList.add("flex", "flex-row", "align-center");

		const rect = document.createElement("div");
		item.append(rect);
		const info = { classNames: [], ...props, extendedProps: props };
		this.importEventAppearance(info);
		rect.style.width = "1.6em";
		rect.style.height = "1em";
		rect.style.border = "2px solid " + info.borderColor;
		rect.style.borderRadius = "3px";
		rect.style.backgroundColor = info.backgroundColor;
		rect.style.color = info.textColor;
		rect.classList.add(...info.classNames);

		const text = document.createElement("div");
		item.append(text);
		text.classList.add("mx-2", "bold");
		text.innerText = label;

		return item;
	}
}
