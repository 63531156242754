import { getCurrentEmployee } from "./PMUtils";

// These fields are computed from the dialog values, we don't want to show them in the dialog.
const COMPUTED_FIELDS = ["hours", "from_time", "to_time"];

export class PMTimeLogDialog {
	constructor(values, callback) {
		this._values = Object.assign({}, values || {});
		for (const key in values) {
			if (this._values[key] == undefined) {
				delete this._values[key];
			}
		}

		this.is_new = !this._values.name;
		this.callback = callback;
	}

	async show() {
		await frappe.model.with_doctype("Timesheet");
		this.parent_meta = frappe.get_meta("Timesheet");
		this.meta = frappe.get_meta("Timesheet Detail");

		this.dialog = new frappe.ui.Dialog({
			title: __("Log Time"),
			fields: await this.get_all_fields(),
			primary_action: this.primary_action.bind(this),
			primary_action_label: this.is_new ? __("Create") : __("Update"),
			secondary_action: () => this.dialog?.hide(),
			secondary_action_label: __("Cancel"),
		});
		if (!this.is_new) {
			this.dialog.add_custom_action(__("Delete"), this.delete_action.bind(this), "btn-danger")
		}
		this.dialog.set_values(this._values);
		this.dialog.show();
	}

	async delete_action() {
		const method = "erpnext.projects.doctype.timesheet.timesheet.update_time_log";
		await frappe.xcall(method, { values: { name: this._values.name }, delete: true });

		this.dialog?.hide();
		this.dialog = null;

		this.callback?.();
	}

	async primary_action(values) {
		if (!values.duration) return;

		const start_dt = values.date + " " + values.start_time;
		const hours = values.duration / 3600;
		delete values.date;
		delete values.start_time;
		delete values.duration;

		values.hours = hours;
		values.from_time = start_dt;
		values.to_time = null;

		values.name = this._values.name;

		for (const df of await this.get_all_fields()) {
			if (df.fieldname && values[df.fieldname] === undefined) {
				values[df.fieldname] = null;
			}
		}

		const method = this.is_new
			? "erpnext.projects.doctype.timesheet.timesheet.add_time_log"
			: "erpnext.projects.doctype.timesheet.timesheet.update_time_log";
		await frappe.xcall(method, { values });

		this.dialog?.hide();
		this.dialog = null;

		this.callback?.();
	}

	_get_field(fieldname) {
		return this.meta.fields.find(df => df.fieldname === fieldname)
	}

	_get_parent_field(fieldname) {
		return this.parent_meta.fields.find(df => df.fieldname === fieldname)
	}

	async get_all_fields() {
		if (this._all_fields) {
			return this._all_fields;
		}
		const isFieldAlreadySpecified = (fieldname) => {
			return (
				base_fields.some((df) => df.fieldname === fieldname) || COMPUTED_FIELDS.includes(fieldname)
			);
		};
		const required_fields = this.meta.fields.filter(
			(df) => df.reqd || df.in_list_view || df.allow_in_quick_entry
		);
		const base_fields = await this._get_custom_fields();
		const missing_fields = required_fields.filter((df) => !isFieldAlreadySpecified(df.fieldname));
		this._all_fields = [...base_fields];
		if (missing_fields.length) {
			this._all_fields.push({ fieldtype: "Section Break", label: __("More Information"), hide_border: 1, collapsible: 1 });
			this._all_fields.push(...missing_fields);
		}
		return this._all_fields;
	}

	async _get_custom_fields() {
		const field = (fieldname, overrides = {}) => ({
			...this._get_field(fieldname),
			...overrides,
		});
		const parentfield = (fieldname, overrides = {}) => ({
			...this._get_parent_field(fieldname),
			...overrides,
		});

		const currentEmployee = await getCurrentEmployee();
		const NO_DEPENDS_ON = { depends_on: "", read_only_depends_on: "", mandatory_depends_on: "" };

		return [
			{
				fieldtype: "Duration",
				fieldname: "duration",
				label: "Duration",
				hide_days: 1,
				hide_seconds: 1,
				non_negative: 1,
				reqd: 1,
			},
			{ fieldtype: "Column Break" },
			{
				fieldtype: "Time",
				fieldname: "start_time",
				label: "Start Time",
				reqd: 1,
				default: "09:00:00",
			},
			{
				fieldtype: "Date",
				fieldname: "date",
				label: "Date",
				reqd: 1,
			},
			{ fieldtype: "Section Break", label: "Details", hide_border: 1, collapsible: 1 },
			field("project", {
				...NO_DEPENDS_ON,
				get_query: () => ({
					filters: {
						status: "Open",
					},
				}),
			}),
			field("activity_type", NO_DEPENDS_ON),
			// {
			// 	fieldtype: "Link",
			// 	fieldname: "user",
			// 	options: "User",
			// 	label: "User",
			// 	default: frappe.session.user,
			// },
			{ fieldtype: "Column Break" },
			field("task", {
				depends_on: "",
				read_only_depends_on: "",
				mandatory_depends_on: "",
				get_query: () => ({
					filters: {
						project: this.dialog.get_value("project"),
					},
				}),
			}),
			{
				fieldtype: "Link",
				fieldname: "employee",
				options: "Employee",
				label: "Employee",
				...parentfield("employee"),
				default: currentEmployee,
				// depends_on: "eval:!doc.employee",
			},
			{ fieldtype: "Section Break", label: __("Description"), hide_border: 1, collapsible: 1 },
			field("description", NO_DEPENDS_ON),
		];
	}
}