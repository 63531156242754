import interactionPlugin from "@fullcalendar/interaction";
import adaptivePlugin from "@fullcalendar/adaptive";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";
import dayGridPlugin from "@fullcalendar/daygrid";

import { PMViewBaseCalendar } from "./PMViewBaseCalendar";
import { PMSource_TaskAssigmentsAssign } from "./eventSources";
import { getDocumentTitle } from "./PMViewEventSource";

export class PMViewCalendar_Assignments extends PMViewBaseCalendar {
	eventSources = [
		new PMSource_TaskAssigmentsAssign(),
	];

	/**
	 * @param {Object} opts
	 * @param {unknown} opts.page
	 * @param {unknown} opts.view
	 * @param {HTMLElement} opts.parent
	 */
	constructor(opts) {
		super(opts);
		this.page = opts.page;
	}

	async fetchResources(parameters, callback) {
		const resources = await frappe.xcall("frappe.desk.calendar.get_resource_ids", {
			doctype: this.eventSources[0].doctype,
			resource: "assigned_to.employee",
		});
		return callback(resources);
	}

	/** @override */ get_calendar_options() {
		return {
			...super.get_calendar_options(),

			schedulerLicenseKey: frappe.boot.fullcalendar_scheduler_licence_key,
			plugins: [
				resourceTimelinePlugin,
				interactionPlugin,
				adaptivePlugin,
				resourceTimeGridPlugin,
				dayGridPlugin,
			],

			// Appearance
			resourceAreaWidth: "150px",

			// Views
			initialView: "pmWeek",
			views: {
				pmBase: {
					type: "resourceTimeline",
					duration: { weeks: 1 },
					slotDuration: { days: 1 },
					nowIndicator: false,
					expandRows: true,
				},
				pmWeek: { type: "pmBase", duration: { weeks: 1 } },
				pmMonth: { type: "pmBase", duration: { months: 1 } },
			},
			headerToolbar: {
				left: "prev,next today",
				center: "title",
				right: "pmWeek,pmMonth dayGridMonth",
			},

			// Features
			editable: true,
			selectable: true,
			displayEventTime: false,
			refetchResourcesOnNavigate: false, // false because resources won't change when changing the date!
			selectMirror: false,
			showNonCurrentDates: true,
			weekends: true,

			// Data
			resourceAreaColumns: [
				{
					// headerContent: __(this.Resource.doctype),
					field: "title",
					headerDidMount: this.resourceColumnHeaderDidMount?.bind(this),
				},
			],

			// https://fullcalendar.io/docs/resourceGroupField
			// resourceGroupField: "group",
			resources: this.fetchResources.bind(this),
		};
	}

	async make() {
		// add links to other calendars
		this.page.clear_user_actions();
		$(this.parent).on("show", this.refresh.bind(this));

		await super.make();

		this.footnote_area = frappe.utils.set_footnote(
			this.footnote_area,
			this.parent,
			__("Select or drag across time slots to create a new event.")
		);
		this.footnote_area.css({ "border-top": "0px" });
	}

	destroy() {
		super.destroy();
		this.page.clear_user_actions();
		this.footnote_area?.remove();
	}

	resourceColumnHeaderDidMount(info) {
		// Because of the multiple "overflow: hidden" present in the parents of the resource column header,
		// we have to have a button + popover solution instead of a simple input.
		const main = info.el.querySelector(".fc-datagrid-cell-main")
		main.innerHTML = "";

		const button = document.createElement("button");
		button.classList.add("btn", "btn-default", "btn-sm", "flex", "justify-between", "align-center");
		button.innerHTML = `${frappe.utils.icon("es-line-add", "xs")}&nbsp;<span>${__("Row")}</span>`;
		main.appendChild(button);

		const input_wrapper = document.createElement("div");
		const input_add_resource = frappe.ui.form.make_control({
			parent: input_wrapper,
			df: {
				fieldtype: "Link",
				fieldname: "add_resource",
				options: "Employee",
				get_query: () => {
					return {
						filters: {
							"name": ["not in", this.fullcalendar.getResources().map((r) => r.id)],
						},
					};
				},
				change: async () => {
					// Add this resource to the list of resources
					const new_resource = input_add_resource.get_value();
					if (!new_resource) return;

					input_add_resource.set_value("");
					this.fullcalendar.addResource({
						id: new_resource,
						title: await getDocumentTitle("Employee", new_resource),
					});
					// this.refresh();
				},
			},
			render_input: true,
			only_input: true,
		});

		$(button).popover({
			html: true,
			animation: false,
			content: input_wrapper,
			placement: "right",
			trigger: "manual",
			container: this.page.parent,
		});

		const isShown = () => {
			const tip = $(button).data("bs.popover")?.tip
			return tip?.classList.contains("show") && tip?.isConnected;
		};

		const hide = () => {
			if (isShown()) {
				$(button).popover("hide");
				button.focus();
			}
		}

		button.addEventListener("click", () => {
			if (!isShown()) {
				$(button).popover("show");
				input_add_resource.$input.focus();
			}
		});

		// Close on awesomplete-close
		input_add_resource.$input.on("awesomplete-close", hide);
		input_wrapper.addEventListener("blur", hide);
	}
}
