import { PMTimeLogDialog } from "./PMTimeLogDialog";
import { getCurrentEmployee } from "./PMUtils";
import { PMViewEventSource } from "./PMViewEventSource";

const withHoursDiplay = (/** @type {PMViewEventSource} */ C) => {
	/** @type {PMViewEventSource} */
	const WithHoursDiplay = class extends C {
		async buildEventTextPart(fieldname, info) {
			if (fieldname === "_hours") {
				const hours = info.extendedProps.weight;
				return {
					fieldname: "Duration",
					title: frappe.format(Math.round(hours * 60) * 60, { fieldtype: "Duration" }),
				}
			}
			return super.buildEventTextPart(fieldname, info);
		}
	}
	WithHoursDiplay.name = C.name + "_WithHoursDiplay";
	return WithHoursDiplay;
}

const extractTime = (x) => {
	if (typeof x !== "string") return x;
	const re = /\d\d:\d\d:\d\d/g;
	const match = x.match(re);
	return match?.[0] ?? x;
}

export class PMSource_TaskAssigments extends withHoursDiplay(PMViewEventSource) {
	doctype = "Task Assignment";
	mainKeys = ["project", "task", "employee", "_hours"];
	field_map = {
		name: "name",
		start: "start_date",
		end: "end_date",
		duration: "duration",
		project: "project",
		task: "task",
	};
}

export class PMSource_TaskAssigmentsLog extends PMSource_TaskAssigments {
	grabEventStyle() {
		return "striped";
	}

	setupLegend(/** @type {HTMLElement} */ container) {
		container.prepend(this.makeLegendItem(__("Assigned"), { color: "blue", doctype: "Task Assignment" }));
	}

	/** @protected */ async getFilters() {
		const filters = await super.getFilters();
		filters.push(["Task Assignment Row", "employee", "=", await getCurrentEmployee()]);
		return filters;
	}

	async getArgs(params) {
		const args = await super.getArgs(params);
		const fm = { ...this.field_map, employee: "assigned_to.employee as employee" };
		args.field_map = fm;
		args.fields = Object.values(fm);
		return args;
	}

	async prepareEvent(event) {
		event = await super.prepareEvent(event);
		event.editable = false;
		event.extendedProps.task_assignment = event.extendedProps.name;
		return event;
	}

	async onSelect(info) {
		// do nothing
	}

	async onEventClick(info) {
		// do nothing
	}

	postProcessEvents(events) {
		return events.flatMap((e) => this.splitEvent(e));
	}

	splitEvent(event) {
		// Split the event into multiple events if it spans multiple days
		const start = event.start;
		const end = event.end;
		// const groupId = event.groupId || event.id || Math.random();

		const days_diff = frappe.datetime.get_day_diff(end, start);

		const out = [];
		for (let i = 0; i < days_diff; i++) {
			const new_event = Object.assign({}, event);
			new_event.extendedProps = Object.assign({}, event.extendedProps);
			new_event.start = frappe.datetime.add_days(start, i);
			new_event.end = new_event.start;
			delete new_event.groupId;
			out.push(new_event);
		}

		if (out.length === 0) {
			out.push(event);
		}

		return out;
	}
}

export class PMSource_TimeLogs extends withHoursDiplay(PMViewEventSource) {
	setupLegend(/** @type {HTMLElement} */ container) {
		container.append(
			this.makeLegendItem(__("Draft"), { color: "blue", docstatus: 0, doctype: "Timesheet Detail" })
		);
		container.append(
			this.makeLegendItem(__("Saved"), { color: "blue", docstatus: 1, doctype: "Timesheet Detail" })
		);
	}

	doctype = "Timesheet Detail";
	method = "erpnext.projects.doctype.timesheet.timesheet.get_time_logs";
	mainKeys = ["project", "task", "activity_type", "_hours"];
	field_map = {
		name: "name",
		start: "from_time",
		end: "to_time",
		hours: "hours",
		task: "task",
		project: "project",
		activity_type: "activity_type",
		description: "description",
	};

	/** @protected */ async getFilters() {
		const filters = await super.getFilters();
		filters.push(["Timesheet", "employee", "=", await getCurrentEmployee()]);
		return filters;
	}

	grabEventStyle(info) {
		if (info.extendedProps.docstatus !== 1) {
			return "solid-alt";
		}
		return "solid";
	}

	async onSelect(info) {
		this.show_dialog_for_event(info);
	}

	exportEventTime(targetDocument, info) {
		const time = extractTime(info.event.extendedProps.sourceData.from_time);
		targetDocument.from_time = info.event.startStr + " " + time;
		targetDocument.to_time = null;
		targetDocument.hours = info.event.extendedProps.sourceData.hours;
		return targetDocument;
	}

	async prepareEvent(event) {
		event.parenttype = "Timesheet";
		event = await super.prepareEvent(event);
		event.editable = false;
		event.startEditable = true;
		event.extendedProps.time_log = event.extendedProps.name;
		event.extendedProps.start_time = extractTime(event.extendedProps.sourceData.from_time);
		return event;
	}

	/** @private */ isDocWritableFromEvent(info) {
		const { doctype, name, docstatus } = this.grabEventDocinfo(info);
		if (!doctype || !name) {
			return false;
		}
		if (doctype !== "Timesheet Detail" && doctype !== "Task Assignment") {
			return false;
		}
		if (docstatus != 0) {
			return false;
		}

		let permDt = doctype;
		if (permDt === "Timesheet Detail") {
			permDt = "Timesheet";
		}
		return frappe.model.can_write(permDt)
	}

	/** @override */
	async onEventClick(info) {
		if (this.isDocWritableFromEvent(info)) {
			info.jsEvent?.preventDefault();
			info.jsEvent?.stopPropagation();

			this.show_dialog_for_event(info);
		}
	}

	async get_task_assignment_for_event(evt) {
		if (evt?.extendedProps?.task_assignment) {
			return await frappe.db.get_doc("Task Assignment", evt.extendedProps.task_assignment);
		}
	}

	async get_time_log_for_event(evt) {
		if (evt?.extendedProps?.doctype === "Timesheet Detail") {
			return evt.extendedProps;
		}
		// if (evt?.extendedProps?.doctype === "Timesheet Detail") {
		// 	return new Promise((resolve, reject) => {
		// 		frappe.call({
		// 			method: "frappe.client.get",
		// 			type: "GET",
		// 			args: {
		// 				doctype: "Timesheet Detail",
		// 				name: evt?.extendedProps?.name,
		// 				parent: "Timesheet",
		// 			},
		// 			callback: (r) => {
		// 				frappe.model.sync(r.message);
		// 				resolve(r.message);
		// 			},
		// 		}).fail(reject);
		// 	})
		// }
	}

	doGuiCreateDocument(info) {
		return this.show_dialog_for_event(info);
	}

	doGuiEditDocument(info) {
		if (this.isDocWritableFromEvent(info)) {
			this.show_dialog_for_event(info);
		}
	}

	async show_dialog_for_event(info) {
		const event = info?.event;

		let tl = await this.get_time_log_for_event(event);
		let ta = null;
		if (!tl) {
			ta = await this.get_task_assignment_for_event(event);
		}

		let duration = (tl?.["hours"] * 3600) || (ta?.["duration"]) || 0;
		duration = Math.round(duration / 60) * 60;
		const start_time = extractTime(tl?.start_time ?? tl?.start_time);
		const values = {
			...(tl || ta),
			name: tl?.name,
			date: info?.startStr ?? info?.event?.startStr,
			duration: duration,
			start_time: start_time,
		};
		const timeLogDialog = new PMTimeLogDialog(values, () => this.refreshCalendar());
		timeLogDialog.show();
	}
}

export class PMSource_TaskAssigmentsAssign extends PMSource_TaskAssigments {
	grabEventStyle() {
		return "border";
	}

	async onSelect(info) {
		this.doGuiCreateDocument(info);
	}

	field_map = {
		name: "name",
		start: "start_date",
		end: "end_date",
		duration: "duration",
		project: "project",
		task: "task",
		employee: "employee",
	}

	async getArgs(params) {
		const args = await super.getArgs(params);
		const fm = { ...this.field_map, employee: "assigned_to.employee as employee" };
		args.field_map = fm;
		args.fields = Object.values(fm);
		return args;
	}

	/** @override */ async prepareEvent(event) {
		event = await super.prepareEvent(event);
		// https://fullcalendar.io/docs/resources-and-events
		event.resourceId = event.extendedProps.employee;
		return event;
	}

	exportEvent(doc, info) {
		doc = super.exportEvent(doc, info);

		const resourceId = info?.newResource?.id ?? info?.resource?.id;
		if (resourceId) {
			doc.assigned_to = [
				{
					employee: resourceId,
				}
			]
		}

		return doc;
	}
}
