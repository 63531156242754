let myOwnEmployee = "";
let overridenEmployee = "";

export async function getCurrentEmployee() {
	if (overridenEmployee) {
		return overridenEmployee;
	}
	if (!myOwnEmployee) {
		const res = await frappe.db.get_value("Employee", { user_id: frappe.session.user }, "name");
		myOwnEmployee = res?.message?.name;
	}
	return myOwnEmployee;
}

export function viewAsAnotherEmployee(employee = "") {
	overridenEmployee = employee;
}
