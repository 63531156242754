erpnext.ItemCatalog = class ItemCatalog {
	constructor(opts) {
		this.opts = opts;
	}

	get frm() {
		return this.opts.frm;
	}

	async render() {
		await frappe.require("erpnext_item_catalog.bundle.js");
		this.catalog = new erpnext.ItemCatalogRender(this.opts);
	}

	async show_catalog() {
		if (!this.render_promise) {
			this.render_promise = this.render();
		}
		await this.render_promise;
		this.catalog?.show()
	}

	async show_button() {
		if (!this.$btn) {
			await this.make_button();
		}
		this.$btn.show();
	}

	async hide_button() {
		this.$btn?.hide();
	}

	/**
	 * Feel free to override this method to customize the button's location
	 * @returns {jQuery}
	 */
	make_button() {
		const grid = this.frm.get_field("items").grid;
		const cb = () => this.show_catalog();
		this.$btn = grid.add_custom_button(__("Catalog"), cb, "top");
	}
}
