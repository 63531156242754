frappe.provide("frappe.ui.form");

frappe.ui.form.TaskAssignmentQuickEntryForm = class TaskAssignmentQuickEntryForm extends frappe.ui.form.QuickEntryForm {
	constructor(doctype, after_insert, init_callback, doc, force) {
		super(doctype, after_insert, init_callback, doc, force);
		this.skip_redirect_on_error = true;
	}

	set_meta_and_mandatory_fields() {
		super.set_meta_and_mandatory_fields();

		const fields = this._get_all_fields();
		const missing_fields = this.mandatory.filter(m => fields.every(df => df.fieldname !== m.fieldname));
		this.mandatory = fields.concat(missing_fields);
	}

	render_dialog() {
		var me = this;

		let title = "";
		const is_new = this.doc?.name?.startsWith?.("new-task-assignment-");
		if (is_new) {
			title = __("New {0}", [__(this.doctype)], this.doctype);
		} else {
			title = __("Edit {0}", [__(this.doctype)], this.doctype);
		}

		this.dialog = new frappe.ui.Dialog({
			title: title,
			fields: this.mandatory,
			doc: this.doc,

			// extras
			no_focus: true,
			// static: true,
		});

		if (!is_new) {
			this.dialog.add_custom_action(__("Delete"), async () => {
				await frappe.db.delete_doc(this.doctype, this.doc.name);
				this.dialog.hide();
				this.after_insert(null);
			}, "btn-danger");
		}

		this.register_primary_action();
		!this.force && this.render_edit_in_full_page_link();
		// ctrl+enter to save
		this.dialog.wrapper.keydown(function (e) {
			if ((e.ctrlKey || e.metaKey) && e.which == 13) {
				if (!frappe.request.ajax_count) {
					// not already working -- double entry
					me.dialog.get_primary_btn().trigger("click");
					e.preventDefault();
					return false;
				}
			}
		});

		this.dialog.onhide = () => (frappe.quick_entry = null);
		this.dialog.show();
		this.dialog.get_close_btn().show();

		this.dialog.refresh_dependency();
		this.set_defaults();

		if (this.init_callback) {
			this.init_callback(this.dialog);
		}

		this.onModeChange();
	}

	_get_field(fieldname) {
		return frappe.meta.get_docfield(this.doctype, fieldname, this.doc.name);
	}

	_get_all_fields() {
		const field = (fieldname, overrides = {}) => {
			const df = this._get_field(fieldname);
			Object.assign(df, overrides);
			return df;
		}
		const defaultMode = this.doc?.end_time ? "Time" : "Duration";
		const NO_DEPENDS_ON = { depends_on: "", read_only_depends_on: "", mandatory_depends_on: "" };
		return [
			{
				fieldtype: "Select",
				fieldname: "mode",
				label: "Mode",
				options: ["Duration", "Time"],
				default: defaultMode,
				onchange: this.onModeChange.bind(this),
			},
			{ fieldtype: "Section Break" },
			field("duration"),
			field("start_time"),
			field("end_time"),
			{ fieldtype: "Column Break" },
			field("start_date"),
			field("end_date"),
			{ fieldtype: "Section Break" },
			field("project", {
				...NO_DEPENDS_ON,
				get_query: () => ({
					filters: {
						status: "Open",
					},
				}),
			}),
			field("task", {
				...NO_DEPENDS_ON,
				get_query: () => ({
					filters: {
						project: this.dialog.get_value("project"),
					},
				}),
			}),
		];
	}

	getMode() {
		const mode = this.dialog?.get_value("mode");
		return mode || "Duration";
	}

	isInDurationMode() {
		return this.getMode() === "Duration";
	}

	onModeChange() {
		const inDurationMode = this.isInDurationMode();
		this.dialog.set_df_property("duration", "hidden", !inDurationMode);
		this.dialog.set_df_property("start_time", "hidden", inDurationMode);
		this.dialog.set_df_property("end_time", "hidden", inDurationMode);
	}

	async insert() {
		if (this.isInDurationMode()) {
			await this.dialog.set_value("start_time", "");
			await this.dialog.set_value("end_time", "");
		} else {
			await this.dialog.set_value("duration", "");
		}
		return super.insert();
	}
}