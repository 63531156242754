import { PMViewBaseCalendar } from "./PMViewBaseCalendar";
import { PMSource_TaskAssigmentsLog, PMSource_TimeLogs } from "./eventSources";

export class PMViewCalendar_Timelog extends PMViewBaseCalendar {
	eventSources = [
		new PMSource_TimeLogs(),
		new PMSource_TaskAssigmentsLog(),
	]

	async postProcessEvents(events) {
		const extractDate = (s) => {
			if (typeof s !== "string") return s;
			const re = /\d\d\d\d-\d\d-\d\d/g;
			const match = s.match(re);
			return match?.[0] ?? s;
		}

		for (const e of events) {
			e.start = extractDate(e.start);
			e.end = extractDate(e.end);
		}

		const groups = this.groupEventsByKey(events, (event) => {
			return [
				extractDate(event.start),
				event.extendedProps.project,
				event.extendedProps.task,
				event.extendedProps.employee,
			].map(x => String(x || "")).join("/");
		});

		// Try to match a "Timesheet Detail" event with a "Task Assignment" event.
		const sortKey = (x) => Number(+x.weight) || 0;
		const finalEvents = [];
		for (const [_key, groupEvents] of groups) {
			// Sort events into two arrays depending on the doctype.
			const tas = [];
			const tls = [];
			for (const e of groupEvents) {
				const dt = e.extendedProps.doctype;
				if (dt === "Task Assignment") {
					tas.push(e);
				} else if (dt === "Timesheet Detail") {
					tls.push(e);
				} else {
					finalEvents.push(e);
				}
			}

			// Sort by weight (number of hours).
			tas.sort((a, b) => sortKey(a) - sortKey(b));
			tls.sort((a, b) => sortKey(a) - sortKey(b));

			// Pair up by closest weight.
			const queue = [...tls];
			for (const ta of tas) {
				// Find the closest weight.
				let best = null;
				let bestDist = Infinity;
				for (const tl of queue) {
					const dist = Math.abs(sortKey(ta) - sortKey(tl));
					if (dist < bestDist) {
						best = tl;
						bestDist = dist;
					}
				}
				if (best) {
					// We found a matching log for the TA,
					// show the log but not the TA.
					queue.splice(queue.indexOf(best), 1);
					finalEvents.push(best);
				} else {
					// TA is not yet logged, show it in the UI.
					finalEvents.push(ta);
				}
			}

			// Add all remaining/unmatched logs.
			finalEvents.push(...queue);
		}
		return finalEvents;
	}

	groupEventsByKey(events, keyer) {
		/** @type Record<string, object[]> */
		const out = {};
		for (const event of events) {
			const key = keyer(event);
			if (!out[key]) {
				out[key] = [];
			}
			out[key].push(event);
		}
		return Object.entries(out).sort(([k1], [k2]) => k1 - k2);
	}
}
