
	frappe.templates['call_link'] = `<div class="call-detail-wrapper">
	<div class="head flex justify-between">
		<div>
			<span class="bold"> {{ type }} Call</span>
			{% if (duration) %}
			<span class="text-muted"> • {{ frappe.format(duration, { fieldtype: "Duration" }) }}</span>
			{% endif %}
			<span class="text-muted"> • {{ comment_when(creation) }}</span>
		</div>
		<span>
			<a class="action-btn" href="/app/call-log/{{ name }}" title="{{ __("Open Call Log") }}">
				<svg class="icon icon-sm">
					<use href="#icon-link-url" class="like-icon"></use>
				</svg>
			</a>
		</span>
	</div>


	<div class="body pt-3">
		{% if (type === "Incoming") { %}
			<span> Incoming call from {{ from }}, received by {{ to }}</span>
		{% } else { %}
			<span> Outgoing Call made by {{ from }} to {{ to }}</span>
		{% } %}
		<div class="summary pt-3">
		{% if (summary) { %}
			<i>{{ summary }}</i>
		{% } else { %}
			<i class="text-muted">{{ __("No Summary") }}</i>
		{% } %}
		</div>
		{% if (recording_url) { %}
		<div class="margin-top">
				<audio
					controls
					src="{{ recording_url }}">
				</audio>
		</div>
		{% } %}
	</div>
</div>`;
